import Link from 'next/link';
import { ChainId } from '@baseswapfi/sdk-core';
import { useTranslations } from 'next-intl';
interface WhatsNewUpdate {
  id: number;
  date: string;
  message?: React.ReactNode;
  messageKey?: string;
  chains?: ChainId[];
  messageParams?: Record<string, string>; // Add this
}
export const getUpdatesForChain = (chainId: ChainId) => {
  return updates.filter(update => !update.chains || update.chains.includes(chainId));
};
export const updates: WhatsNewUpdate[] = [{
  id: 14,
  date: '2025-02-18',
  messageKey: 'rangeBasedIncentives',
  messageParams: {
    symbol1: 'WETH-USDC'
  },
  chains: [ChainId.BASE]
}, {
  id: 13,
  date: '2025-01-16',
  messageKey: 'language6',
  messageParams: {
    symbol1: 'Bonjour',
    symbol2: 'Guten Tag'
  },
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 12,
  date: '2025-01-10',
  messageKey: 'language5',
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 11,
  date: '2025-01-06',
  messageKey: 'language4',
  messageParams: {
    symbol1: 'Bienvenidos',
    symbol2: 'Benvenuti'
  },
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 10,
  date: '2025-01-03',
  messageKey: 'language3',
  messageParams: {
    symbol1: 'ようこそ',
    symbol2: 'शुभकामनाएं'
  },
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 9,
  date: '2025-01-02',
  messageKey: 'language2',
  messageParams: {
    symbol: '你好'
  },
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 8,
  date: '2024-12-31',
  messageKey: 'language1',
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 7,
  date: '2024-12-31',
  messageKey: 'newYears2025',
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 6,
  date: '2024-12-24',
  message: <>
        🎄 🎅 Wishing you and your families a very Merry Christmas and a season of joy and
        happiness! Thank you for being a part of this community! Merry Christmas! ❄️ ☃️
      </>,
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE, ChainId.MODE]
}, {
  id: 5,
  date: '2024-12-04',
  message: <>
        New listing frenzy! AIXBT, DONNIE, FUEGO and SKI now live in{' '}
        <Link href="/swap" style={{
      textDecoration: 'underline'
    }}>
          Swap
        </Link>
        !
      </>,
  chains: [ChainId.BASE]
}, {
  id: 4,
  date: '2024-12-03',
  message: <>
        New listing alert! Join the Virtuals craze on BaseSwap!{' '}
        <Link href="/swap" style={{
      textDecoration: 'underline'
    }}>
          Trade
        </Link>{' '}
        Chaos, Clunker, Joy, Music, Pondhub and Simmi!
      </>,
  chains: [ChainId.BASE]
}, {
  id: 3,
  date: '2024-12-02',
  message: <>
        Introducing new fee tiers! We now support V3 positions with 0.005%, 0.20%, 0.85% and 2%! Get
        started&nbsp;
        <Link href="/addV3" style={{
      textDecoration: 'underline'
    }}>
          here
        </Link>
        !
      </>,
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE]
}, {
  id: 2,
  date: '2024-11-28',
  message: `Happy Thanksgiving to everyone celebrating in the U.S.! Wherever you are, we're grateful for you and glad you're a part of this amazing DeFi community! 🦃`
}, {
  id: 1,
  date: '2024-11-27',
  message: `Wondering what's new? Check back here for the latest updates!`
}];